import React, { useState, useEffect } from "react";
import { Button, Form, InputGroup, ProgressBar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FaSearch, FaArrowRight } from "react-icons/fa";
import WithoutSearchBar from "../../../header/WithoutSearchBar";
import api from "../../../../Services/api";

const Farmers = () => {
  const [farmerData, setFarmerData] = useState([]);
  const [statusFilter, setStatusFilter] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  const AllFarmers = async () => {
    try {
      const response = await api.get("/farmers");
      setFarmerData(response.data || []); // Ensure data is set to an array if undefined
    } catch (error) {
      console.error("Error fetching farmer data:", error);
    }
  };

  useEffect(() => {
    AllFarmers();
  }, []);

  // Filter data based on status and search query
  const filteredData = farmerData.filter((farmer) => {
    const matchesStatus =
      statusFilter === "All" || farmer.status === statusFilter;
    const matchesSearchQuery =
      (farmer.farmerName || "")
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      (farmer.address || "").toLowerCase().includes(searchQuery.toLowerCase());
    return matchesStatus && matchesSearchQuery;
  });

  // Pagination Logic
  const totalItems = filteredData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value.trim());
    setCurrentPage(1); // Reset to first page when search is performed
  };

  return (
    <div className="container">
      <div className="header-component">
        <WithoutSearchBar title="Farmers" />
      </div>
      <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center mb-4">
        <InputGroup className="mb-2 mb-md-0" style={{ maxWidth: "300px" }}>
          <InputGroup.Text
            style={{
              borderRadius: "25px 0px 0px 25px",
              backgroundColor: "white",
              border: "none",
            }}
          >
            <FaSearch />
          </InputGroup.Text>
          <Form.Control
            placeholder="Search Farmers"
            value={searchQuery}
            onChange={handleSearch}
            style={{ borderRadius: "0px 25px 25px 0px", border: "none" }}
          />
        </InputGroup>
      </div>

      {/* Table to display the data */}
      <div style={{ overflowX: "auto", marginBottom: "20px" }}>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th className="text-center">Sl. No.</th>
              <th className="text-center">Farmer Name</th>
              <th className="text-center">Address</th>
              <th className="text-center">Joined Date</th>
              <th className="text-center">No of Lands</th>
              <th className="text-center">KYC Status</th>
              <th className="text-center">Completion</th>
              <th className="text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.length > 0 ? (
              currentItems.map((row, index) => (
                <tr key={row.id}>
                  <td className="text-center">
                    {(currentPage - 1) * itemsPerPage + index + 1}
                  </td>
                  <td>{row.farmerName}</td>
                  <td className="text-center">{row.address || "N/A"}</td>
                  <td className="text-center">
                    {new Date(row.createdDate).toLocaleDateString("en-GB")}
                  </td>
                  <td className="text-center">2</td>
                  <td className="text-center">
                    <span
                      style={{
                        color: "#0B671A",
                        backgroundColor: "#1A932E2E",
                        padding: "7px",
                        borderRadius: "25px",
                        fontSize: "0.8rem",
                        fontWeight: "600",
                      }}
                    >
                      Completed
                    </span>
                  </td>
                  <td className="text-center">
                    <ProgressBar
                      variant="success"
                      now={20}
                      label={`${20}%`}
                      style={{ margin: "5px", backgroundColor: "#1A932E2E" }}
                    />
                  </td>
                  <td className="text-center">
                    <FaArrowRight
                      className="action-icon cursor-pointer"
                      // onClick={() => handleAction(row.id)}
                      style={{ cursor: "pointer", color: "#0B671A" }}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8" className="text-center">
                  No Data Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      <div className="d-flex justify-content-center align-items-center my-3">
        {[...Array(totalPages).keys()].map((pageNumber) => (
          <Button
            key={pageNumber + 1}
            variant={
              currentPage === pageNumber + 1 ? "primary" : "outline-primary"
            }
            onClick={() => handlePageChange(pageNumber + 1)}
            className="mx-1"
          >
            {pageNumber + 1}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default Farmers;
