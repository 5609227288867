import React, { useState, useEffect } from "react";
import FarmerInfo from "./componenets/FarmerInfo";
import MapInfoGraph from "./componenets/MapInfoGraph";
import WithoutSearchBar from "../../../../header/WithoutSearchBar";
import {
  FaArrowsAltH,
  FaSeedling,
  FaCalendarAlt,
  FaSatellite,
} from "react-icons/fa";

const FiledAnalysis = () => {
  const [viewType, setViewType] = useState("NDRE");
  const [activeTab, setActiveTab] = useState("Analytics"); // State to manage active tab

  const [filedData, setFiledData] = useState({
    BasicData: {
      farmSize: 200,
      cropType: "Wheat",
      startDate: "01 Jan 2024",
      endDate: "30 Dec 2024",
      satelliteStatus: "Active",
    },
    mapData: {
      NDVI: "/assets/images/NDVI.jpg",
      NDRE: "/assets/images/NDRE.jpg",
      Vegetation: "/assets/images/VEG.jpg",
    },
    graphData: {
      NDVI: {
        type: "NDVI",
        data: [
          { id: 1, month: "Jan", value: 0.72 },
          { id: 2, month: "Feb", value: 0.75 },
          { id: 3, month: "Mar", value: 0.8 },
          { id: 4, month: "Apr", value: 0.78 },
          { id: 5, month: "May", value: 0.65 },
          { id: 6, month: "Jun", value: 0.7 },
          { id: 7, month: "Jul", value: 0.73 },
          { id: 8, month: "Aug", value: 0.74 },
          { id: 9, month: "Sep", value: 0.76 },
          { id: 10, month: "Oct", value: 0.77 },
          { id: 11, month: "Nov", value: 0.8 },
          { id: 12, month: "Dec", value: 0.82 },
        ],
      },
      NDRE: {
        type: "NDRE",
        data: [
          { id: 1, month: "Jan", value: 0.6 },
          { id: 2, month: "Feb", value: 0.63 },
          { id: 3, month: "Mar", value: 0.68 },
          { id: 4, month: "Apr", value: 0.66 },
          { id: 5, month: "May", value: 0.62 },
          { id: 6, month: "Jun", value: 0.64 },
          { id: 7, month: "Jul", value: 0.65 },
          { id: 8, month: "Aug", value: 0.67 },
          { id: 9, month: "Sep", value: 0.7 },
          { id: 10, month: "Oct", value: 0.72 },
          { id: 11, month: "Nov", value: 0.74 },
          { id: 12, month: "Dec", value: 0.75 },
        ],
      },
      Vegetation: {
        type: "Vegetation",
        data: [
          { id: 1, month: "Jan", value: 0.6 },
          { id: 2, month: "Feb", value: 0.63 },
          { id: 3, month: "Mar", value: 0.68 },
          { id: 4, month: "Apr", value: 0.66 },
          { id: 5, month: "May", value: 0.62 },
          { id: 6, month: "Jun", value: 0.64 },
          { id: 7, month: "Jul", value: 0.67 },
          { id: 8, month: "Aug", value: 0.69 },
          { id: 9, month: "Sep", value: 0.71 },
          { id: 10, month: "Oct", value: 0.73 },
          { id: 11, month: "Nov", value: 0.74 },
          { id: 12, month: "Dec", value: 0.75 },
        ],
      },
    },
  });

  const handleViewTypeChange = (e) => {
    setViewType(e.target.value);
  };

  useEffect(() => {
    // Any logic to fetch or update data if necessary can go here.
  }, [viewType]);

  return (
    <div className="container-fluid">
      <WithoutSearchBar />
      {/* Tab navigation */}
      <div className="row mb-3">
        <div className="col">
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <button
                className={`nav-link ${
                  activeTab === "Analytics" ? "active" : "btn btn-light"
                }`}
                style={{
                  backgroundColor: activeTab === "Analytics" ? "#7CAB5E" : "",
                  color: activeTab === "Analytics" ? "white" : "",
                }}
                onClick={() => setActiveTab("Analytics")}
              >
                Analytics
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`nav-link ${
                  activeTab === "Documents" ? "active" : "btn btn-light"
                }`}
                style={{
                  backgroundColor: activeTab === "Documents" ? "#7CAB5E" : "",
                  color: activeTab === "Documents" ? "white" : "",
                }}
                onClick={() => setActiveTab("Documents")}
              >
                Documents
              </button>
            </li>
          </ul>
        </div>
      </div>

      {/* Tab content */}
      {activeTab === "Analytics" && (
        <div className="row rounded mb-1 p-2">
          {/* Left Column */}
          <div className="col-md-8 d-flex align-items-stretch h-100">
            <div className="card rounded w-100 h-100">
              <div className="card-header">
                <div className="d-flex justify-content-between align-items-center">
                  <label className="form-label mb-0">
                    {viewType} Map Analytics
                  </label>
                  <div>
                    <select
                      className="form-select border-0"
                      value={viewType}
                      onChange={handleViewTypeChange}
                    >
                      <option value="Vegetation">Vegetation Meta Index</option>
                      <option value="NDRE">NDRE</option>
                      <option value="NDVI">NDVI</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <img
                  src={filedData.mapData[viewType]}
                  alt={`${viewType} Map`}
                  className="img-fluid rounded"
                />
              </div>
            </div>
          </div>

          {/* Right Column */}
          <div className="col-md-4 d-flex align-items-stretch h-100">
            <div className="card rounded w-100 h-100 overflow-auto">
              <div className="container" style={{ fontFamily: "Inter" }}>
                {/* Card 1 - Farm Size */}
                <div className="d-flex align-items-center p-1 mb-2 border rounded bg-light">
                  <div
                    className="bg-danger text-white p-2 rounded-circle"
                    style={{ marginRight: "10px" }}
                  >
                    <FaArrowsAltH size={30} />
                  </div>
                  <div className="ml-3" style={{ fontFamily: "Inter" }}>
                    Farm Size
                    <p className="mb-0"></p>
                  </div>
                </div>

                {/* Card 2 - Crop Type */}
                <div className="d-flex align-items-center p-1 mb-2 border rounded bg-light">
                  <div
                    className="bg-success text-white p-2 rounded-circle"
                    style={{ marginRight: "10px" }}
                  >
                    <FaSeedling size={30} />
                  </div>
                  <div className="ml-3" style={{ fontFamily: "Inter" }}>
                    Crop Type
                    <p className="mb-0"> </p>
                  </div>
                </div>

                {/* Card 3 - Start Date */}
                <div className="d-flex align-items-center p-1 mb-2 border rounded bg-light">
                  <div
                    className="bg-primary text-white p-2 rounded-circle"
                    style={{ marginRight: "10px" }}
                  >
                    <FaCalendarAlt size={30} />
                  </div>
                  <div className="ml-3" style={{ fontFamily: "Inter" }}>
                    Start Date
                    <p className="mb-0"></p>
                  </div>
                </div>

                {/* Card 4 - End Date */}
                <div className="d-flex align-items-center p-1 mb-2 border rounded bg-light">
                  <div
                    className="bg-warning text-white p-2 rounded-circle"
                    style={{ marginRight: "10px" }}
                  >
                    <FaCalendarAlt size={30} />
                  </div>
                  <div className="ml-3" style={{ fontFamily: "Inter" }}>
                    End Date
                    <p className="mb-0"></p>
                  </div>
                </div>

                {/* Card 5 - Satellite */}
                <div className="d-flex align-items-center p-1 mb-3 border rounded bg-light">
                  <div
                    className="bg-info text-white p-2 rounded-circle"
                    style={{ marginRight: "10px" }}
                  >
                    <FaSatellite size={30} />
                  </div>
                  <div className="ml-3">
                    Satellite
                    <p className="mb-0"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {activeTab === "Documents" && (
        <div className="row justify-content-center text-center">
          <div className="col mb-4 align-items-center mt-3">
            <table
              cellSpacing="0"
              cellPadding="5"
              style={{
                width: "70%",
                borderCollapse: "collapse",
                margin: "auto",
              }}
            >
              <tbody>
                <tr>
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    <strong>Property Description</strong>
                  </td>
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    P1: Paddy farm land under Soft Title Deed issued by Peam
                    Chhkouk Commune.
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    <strong>Tenure</strong>
                  </td>
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    P1: Freeholder
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    <strong>Land Use</strong>
                  </td>
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    P1: Paddy farm Land:
                    <br /> - Land Size: 62,500 m2
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    <strong>Register owner/ Relationship</strong>
                  </td>
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    P1: Mr. OUCH KOEMHENG and Mrs. UN CHHENGHUN
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    <strong>Occupant</strong>
                  </td>
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    P1: Paddy farm Land
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    <strong>Restrict Interest</strong>
                  </td>
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    No
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    <strong>Evaluation by</strong>
                  </td>
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    KonektAgri
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    <strong>OMV</strong>
                  </td>
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    P1: Paddy farm Land: 62,500 m2 x USD0.50 = USD 31,250
                    <br />
                    Total amount P1: USD 31,250
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    <strong>Marketability factor</strong>
                  </td>
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    P2: Surround properties there are Paddy farming, irrigation
                    water, under developing area
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    <strong>Address of Property</strong>
                  </td>
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    P1: Kbal Kanlong Village, Peam Chhkouk Commune, Chol Kiri
                    District, KPN Province.
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    <strong>Strength of property area</strong>
                  </td>
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    P1: These areas as farming and enough irrigation source.
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    <strong>Weakness of property area</strong>
                  </td>
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    P1: The property collateral is Paddy farm land with Soft
                    Title Deed and is in small business area
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    <strong>Properties Comparison</strong>
                  </td>
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    We are surveying the property's value with customer and
                    chief of village the land price around our customer
                    property:
                    <br />
                    P1: Land: 1m2 = USD0.50
                    <br />
                    Survey:
                    <br />
                    1/ Deputy chief of Village Kbal Kanlong Mr. SO SRY phone
                    number: 0977606587
                    <br />
                    2/ Chief of Village Kbal Kanlong Mr. PAL SRON phone number:
                    0972481498
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    <strong>Evaluated by</strong>
                  </td>
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    ROM LENIN
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    <strong>Checked by</strong>
                  </td>
                  <td
                    style={{ border: "1px solid black", padding: "8px" }}
                  ></td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    <strong>Verifired by</strong>
                  </td>
                  <td
                    style={{ border: "1px solid black", padding: "8px" }}
                  ></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default FiledAnalysis;
