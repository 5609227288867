import React, { useState, useEffect, useRef } from "react";
import WithoutSearchBar from "../../../header/WithoutSearchBar";
import "@fortawesome/fontawesome-free/css/all.min.css";
import {
  FaCamera,
  FaCloudUploadAlt,
  FaTrash,
  FaTrashAlt,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import api from "../../../../Services/api";
import Swal from "sweetalert2";

const Id_Verification = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("doesn't-hold-KhmerID");
  const [cardType, setcardType] = useState([]);
  const [FormData, setFormData] = useState({
    nameInDoc: "",
    docTypeId: "",
    expiryDate: "",
    cardNumber: "",
    docImgPath: [],
  });

  const [FrontImage, setFrontImage] = useState(null);
  const [backImage, setBackImage] = useState(null);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    console.log(e.target.value);
  };

  const CardList = async () => {
    try {
      const response = await api.get("/documentType");
      console.log(response.data);
      setcardType(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleProceedwithKhmer = () => {
    navigate("/Face_Verification");
  };

  const handleProceedWithoutKhmer = async () => {
    const { nameInDoc, docTypeId, expireDate, cardNumber } = FormData;

    // Validation: Check if any field is empty or null
    if (
      nameInDoc.trim() === "" ||
      docTypeId.trim() === "" ||
      expireDate.trim() === "" ||
      cardNumber.trim() === ""
    ) {
      Swal.fire({
        icon: "warning",
        title: "Warning !",
        text: "All fields are required. Please fill all mandatory fields .",
        showCancelButton: false,
        showConfirmButton: false,
        timer: "3000",
      });
      return; // Exit the function without making the API call
    }

    try {
      const response = await api.post("/kyc", FormData);
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Your KYC details have been submitted successfully.",
        showCancelButton: false,
        showConfirmButton: false,
        timer: "2000",
      });
      navigate("/Face_Verification");
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        title: "Submission Error",
        text: "An error occurred while submitting your KYC details. Please try again.",
      });
    }
  };

  const frontFileInputRef = useRef(null);
  const backFileInputRef = useRef(null);

  const handleFrontCardClick = () => {
    frontFileInputRef.current.click(); // Trigger file input click for the front card
  };

  const handleBackCardClick = () => {
    backFileInputRef.current.click(); // Trigger file input click for the back card
  };

  const handleFrontFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFrontImage(URL.createObjectURL(file)); // Set front image preview
    }
  };

  const handleBackFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setBackImage(URL.createObjectURL(file)); // Set back image preview
    }
  };

  const handleDeletefrontImg = () => {
    setFrontImage(null);
  };
  const handleDeletebackImg = () => {
    setBackImage(null);
  };
  useEffect(() => {
    CardList();
  }, []);

  return (
    <div className="container">
      <div className="header-comonent border-bottom">
        <WithoutSearchBar title="ID Verification" />
      </div>

      <div className="p-3">
        <div className="row mt-3">
          <div className="col-3 form-check">
            <input
              className="form-check-input custom-radio"
              type="radio"
              id="holds-KhmerID"
              value="holds-KhmerID"
              checked={selectedOption === "holds-KhmerID"}
              onChange={handleOptionChange}
            />
            <label className="form-check-label" htmlFor="holds-KhmerID">
              Person holds Khmer ID card
            </label>
          </div>
          <div className="col-3 form-check">
            <input
              className="form-check-input custom-radio"
              type="radio"
              id="doesn't-hold-KhmerID"
              value="doesn't-hold-KhmerID"
              checked={selectedOption === "doesn't-hold-KhmerID"}
              onChange={handleOptionChange}
            />
            <label className="form-check-label" htmlFor="doesn't-hold-KhmerID">
              Person doesn't hold Khmer ID card
            </label>
          </div>
        </div>
      </div>

      <div className="row  p-3">
        {selectedOption === "holds-KhmerID" ? (
          <div class="col-8">
            <form>
              <div class="form-group">
                Note: We prefer to choose the Khemri ID card as it allows
                automatic data retrieval, reducing errors from manual entry and
                enhancing overall data accuracy.
              </div>
              <div class="form-group mt-3">
                <label for="khmerIdInput">Enter Khmer ID</label>
                <div class="input-group mt-2" style={{ position: "relative" }}>
                  <input
                    type="text"
                    class="form-control"
                    id="khmerIdInput"
                    placeholder="Khmer ID"
                    style={{ backgroundColor: "white" }}
                  />
                  <FaCamera
                    style={{
                      padding: "10px",
                      fontSize: "35px",
                      position: "relative",
                      marginLeft: "-80px",
                    }}
                  />
                  <button class="btn btn-success" type="button">
                    Fetch
                  </button>
                </div>
              </div>
              <div class="mt-3 text-muted ">The below fileds are disabled</div>
              <div class="form-group mt-3">
                <label>Full name on ID card</label>
                <input
                  type="text"
                  class="form-control mt-2"
                  placeholder="Name on Khmer ID"
                />
              </div>
              <div class="form-group row mt-3 align-items-center">
                <div class="col-md-6">
                  <label for="dropdownMenuButton">ID Card Type</label>
                  <div class="dropdown">
                    <button
                      class="btn dropdown-toggle form-control mt-2"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      style={{ backgroundColor: "white" }}
                    >
                      Select ID Card Type
                    </button>
                    <div
                      class="dropdown-menu "
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a class="dropdown-item" href="#">
                        Action
                      </a>
                      <a class="dropdown-item" href="#">
                        Another action
                      </a>
                      <a class="dropdown-item" href="#">
                        Something else here
                      </a>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <label for="expireDate">Expiry Date</label>
                  <input
                    type="date"
                    class="form-control mt-2"
                    id="expireDate"
                    placeholder="Select expiry date"
                  />
                </div>
              </div>
              <div class="form-group mt-3">
                <label>ID Number</label>
                <input
                  type="number"
                  class="form-control mt-2"
                  placeholder="ID Number"
                />
              </div>
              <div class="form-group mt-3">
                <button
                  className="btn btn-dark"
                  style={{ width: "100%" }}
                  onClick={handleProceedwithKhmer}
                >
                  Proceed for liveliness
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div class="row">
            <div class="col-8">
              <div class="form-group ">
                <label for="khmerIdInput">
                  Enter your name<span className="text-danger"> *</span>
                </label>
                <div class="input-group mt-2" style={{ position: "relative" }}>
                  <input
                    type="text"
                    class="form-control"
                    id="khmerIdInput"
                    name="nameInDoc"
                    placeholder="Enter your name"
                    style={{ backgroundColor: "white" }}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div class="form-group row mt-3 align-items-center">
                <div class="col-md-6">
                  <label for="dropdownMenuButton">
                    ID Card Type<span className="text-danger"> *</span>
                  </label>
                  <div class="dropdown mt-2">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      name="docTypeId"
                      onChange={handleInputChange}
                    >
                      <option disabled>Select One Id Card</option>
                      {cardType.map((card, index) => (
                        <option value={card.documentTypesId}>
                          {card.documentTypes}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div class="col-md-6">
                  <label for="expiryDate">
                    Expiry Date<span className="text-danger"> *</span>
                  </label>
                  <input
                    type="date"
                    class="form-control mt-2"
                    id="expiryDate"
                    name="expireDate"
                    onChange={handleInputChange}
                    placeholder="Select expiry date"
                  />
                </div>
              </div>
              <div class="form-group mt-3">
                <label for="khmerIdInput">
                  ID Number<span className="text-danger"> *</span>
                </label>
                <div class="input-group mt-2" style={{ position: "relative" }}>
                  <input
                    type="text"
                    class="form-control"
                    id="khmerIdInput"
                    placeholder="ID Number"
                    name="cardNumber"
                    onChange={handleInputChange}
                    style={{ backgroundColor: "white" }}
                  />
                </div>
              </div>

              <div className="form-group row mt-3 align-items-center">
                {/* Front Image Upload */}
                <div className="col-md-6">
                  <label style={{ color: "grey", opacity: "0.8" }}>
                    Upload front image of ID card
                  </label>
                  <div
                    className="card mt-2"
                    style={{ height: "180px", backgroundColor: "white" }}
                  >
                    {FrontImage ? (
                      <>
                        <img
                          src={FrontImage}
                          alt="Back Preview"
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            objectFit: "cover",
                            padding: "10px 20px",
                          }}
                        />
                        <FaTrashAlt
                          style={{
                            position: "absolute",
                            color: "red",
                            top: "5px",
                            right: "5px",
                            cursor: "pointer",
                          }}
                          onClick={handleDeletefrontImg}
                        />
                      </>
                    ) : (
                      <div className="card-body" style={{ cursor: "pointer" }}>
                        <div className="row" style={{ textAlign: "center" }}>
                          <FaCloudUploadAlt
                            style={{
                              borderRadius: "50%",
                              padding: "10px 20px",
                              fontSize: "40px",
                              color: "grey",
                            }}
                            onClick={handleFrontCardClick}
                          />
                        </div>
                        <div>
                          <div
                            className="row"
                            style={{ textAlign: "center", marginTop: "10px" }}
                          >
                            <span
                              style={{ color: "#1A932E", fontWeight: "bold" }}
                            >
                              Click to upload
                            </span>
                          </div>
                          <div
                            className="row"
                            style={{ textAlign: "center", marginTop: "5px" }}
                          >
                            <span style={{ color: "grey", fontSize: "14px" }}>
                              SVG, PNG, JPG, or GIF (max. 800x400px)
                            </span>
                          </div>
                          {/* Hidden File Input for Front Image */}
                          <input
                            type="file"
                            ref={frontFileInputRef}
                            style={{ display: "none" }}
                            accept="image/svg+xml,image/png,image/jpg,image/jpeg,image/gif"
                            onChange={handleFrontFileChange}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* Back Image Upload */}
                <div className="col-md-6">
                  <label style={{ color: "grey", opacity: "0.8" }}>
                    Upload back image of ID card
                  </label>
                  <div
                    className="card mt-2"
                    style={{ height: "180px", backgroundColor: "white" }}
                  >
                    {backImage ? (
                      <>
                        <img
                          src={backImage}
                          alt="Back Preview"
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            objectFit: "cover",
                            padding: "10px 20px",
                          }}
                        />
                        <FaTrashAlt
                          style={{
                            position: "absolute",
                            color: "red",
                            top: "5px",
                            right: "5px",

                            cursor: "pointer",
                          }}
                          onClick={handleDeletebackImg}
                        />
                      </>
                    ) : (
                      <div className="card-body" style={{ cursor: "pointer" }}>
                        <div className="row" style={{ textAlign: "center" }}>
                          <FaCloudUploadAlt
                            style={{
                              borderRadius: "50%",
                              padding: "10px 10px",
                              fontSize: "40px",
                              color: "grey",
                            }}
                            onClick={handleBackCardClick}
                          />
                        </div>
                        <div
                          className="row"
                          style={{ textAlign: "center", marginTop: "10px" }}
                        >
                          <span
                            style={{ color: "#1A932E", fontWeight: "bold" }}
                          >
                            Click to upload
                          </span>
                        </div>
                        <div
                          className="row"
                          style={{ textAlign: "center", marginTop: "5px" }}
                        >
                          <span style={{ color: "grey", fontSize: "14px" }}>
                            SVG, PNG, JPG, or GIF (max. 800x400px)
                          </span>
                        </div>
                        {/* Hidden File Input for Back Image */}
                        <input
                          type="file"
                          ref={backFileInputRef}
                          style={{ display: "none" }}
                          accept="image/svg+xml,image/png,image/jpg,image/jpeg,image/gif"
                          onChange={handleBackFileChange}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div class="form-group mt-3">
                <button
                  className="btn btn-dark"
                  style={{ width: "100%" }}
                  onClick={handleProceedWithoutKhmer}
                >
                  Proceed for liveliness
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Id_Verification;
