import React, { useState, useRef } from "react";
import WithoutSearchBar from "../../../header/WithoutSearchBar";
import { useNavigate } from "react-router-dom";

const FaceVerification = () => {
  const navigate = useNavigate();
  const [stream, setStream] = useState(null);
  const videoRef = useRef(null);

  const startCamera = async () => {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });
      setStream(mediaStream);
      if (videoRef.current) {
        videoRef.current.srcObject = mediaStream;
      }
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  const stopCamera = () => {
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
      setStream(null);
    }
  };

  const handleProceed = () => {
    navigate("/Face_Submit");
  };

  return (
    <div className="container">
      <div className="header-component border-bottom">
        <WithoutSearchBar title="Face Verification" />
      </div>
      <div className="row p-3">
        <div class="col-8">
          <div className="row justify-content-center mt-3">
            <div className="col-auto">
              <label
                className="form-check-label text-center"
                style={{ fontWeight: "600", fontSize: "1.2rem" }}
              >
                Please look to the camera
              </label>
            </div>
          </div>

          <div className="row justify-content-center mt-4">
            <div className="col-auto">
              <video
                ref={videoRef}
                autoPlay
                className="border rounded"
                style={{ width: "100%", maxWidth: "1000px" }}
              />
            </div>
          </div>

          {/* <div className="row justify-content-center mt-4">
            <button
              className="col-6 btn btn-primary mx-2"
              onClick={startCamera}
              disabled={stream !== null}
            >
              Start Camera
            </button>
            <button
              className="col-6 btn btn-danger mx-2"
              onClick={stopCamera}
              disabled={stream === null}
            >
              Stop Camera
            </button>
          </div> */}

          <div class="form-group row mt-3 align-items-center">
            <div class="col-md-6">
              <button class="btn btn-success" onClick={handleProceed}>
                Submit
              </button>
            </div>
          </div>
          <div class="form-group row mt-3 align-items-center">
            <div class="col-md-6">
              <div
                class="row mt-3"
                style={{ fontWeight: "600", fontSize: "0.9rem" }}
              >
                (1) Place your head in the shape above
              </div>
              <div
                class="row mt-2"
                style={{ fontWeight: "600", fontSize: "0.9rem" }}
              >
                (2) Move your head from left to right
              </div>
              <div
                class="row mt-2"
                style={{ fontWeight: "600", fontSize: "0.9rem" }}
              >
                (3) Continue till the bar below is completed.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaceVerification;
