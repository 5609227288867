import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import DashboardIcon from '../../../assets/icons/dashboardIcon';
import FarmersIcon from '../../../assets/icons/farmersIcon';
import SettingsIcon from '../../../assets/icons/settings';
import LandDetailsIcon from '../../../assets/icons/landDetailsIcon';
import KycIcon from '../../../assets/icons/kycIcon';

const Sidebar = () => {
  const location = useLocation();
  useEffect(()=>{
    const menuArr = []
    console.log(window.location.pathname)
  },[location])


  return (
    <div className='sidebar'>
        <div className='menugroup'>
          <div className=''>
          <div className={'sidebar_item '}>
              <div className='d-flex align-items-center'>
                <div className='frame square'>
                    <img src={'/assets/icons/konektAgri_logo.png'} alt='konektagri' width='48px'/>
                  </div>
                  <div className='link-con title'>
                    KonektAgri
                  </div>
                </div>
            </div>
          </div>
        </div>
        <div className='menugroup'>
          <Link to="/Start_KYC_Process">
            <div className={'sidebar_item always'}>
              <div className='d-flex align-items-center'>
                <div className='frame circular'>
                    <img src={'/assets/icons/create.svg'} alt='create'/>
                  </div>
                  <div className='link-con'>
                    Start KYC Process
                  </div>
                </div>
            </div>
          </Link>
        </div>
        <div className='menugroup' id='navPagesMenu'>
          <Link to="/">
            <div className={'sidebar_item '+ (location.pathname == "/" ? "active":"")}>
              <div className='d-flex align-items-center'>
                  <DashboardIcon/>
                  <div className='link-con'>
                    Dashboard
                  </div>
                </div>
            </div>
          </Link>
          <Link to="/farmers">
            <div className={'sidebar_item '+ (location.pathname.includes("/farmers")? "active":"")}>
              <div className='d-flex align-items-center'>
                  <FarmersIcon/>
                  <div className='link-con'>
                    Farmers
                  </div>
                </div>
            </div>
          </Link>
          <Link to="/kycs">
            <div className={'sidebar_item '+ (location.pathname.includes("kycs") ? "active":"")}>
              <div className='d-flex align-items-center'>
                  <KycIcon/>
                  <div className='link-con'>
                    KYCs
                  </div>
                </div>
            </div>
          </Link>
          <Link to="/landDetails">
            <div className={'sidebar_item '+ (location.pathname.includes("/landDetails") ? "active":"")}>
              <div className='d-flex align-items-center'>
                  <LandDetailsIcon/>
                  <div className='link-con'>
                    Land Details
                  </div>
                </div>
            </div>
          </Link>
          <Link to="/menuSettings">
            <div className={'sidebar_item '+ (location.pathname.includes("/menuSettings") ? "active":"")}>
              <div className='d-flex align-items-center'>
                  <SettingsIcon/>
                  <div className='link-con'>
                    Menu Settings
                  </div>
                </div>
            </div>
          </Link>
        </div>

    </div>
  )
}

export default Sidebar
