import React, { useState } from "react";
import WithoutSearchBar from "../../../header/WithoutSearchBar";
import success from "../../../../assets/icons/success.png";
import GreenTick from "../../../../assets/icons/GreenTick.png";
import GreenRadio from "../../../../assets/icons/GreenRadio.png";
import { useNavigate } from "react-router-dom";

const FaceSubmit = () => {
  const [loadingStep, setLoadingStep] = useState(0); // 0: Initial, 1: Reviewing, 2: Submitted
  const [submitted, setSubmitted] = useState(false);
  const Navigate = useNavigate();

  const handleRetake = () => Navigate("/Face_Verification");

  const handleSubmit = () => {
    setLoadingStep(1); // Start review
    setTimeout(() => {
      setLoadingStep(2); // Review complete
      setTimeout(() => {
        setSubmitted(true); // Show final card
      }, 1000);
    }, 3000);
  };

  const handleEKYCProcess = () => Navigate("/e-kyc");
  const handleStartWithCustomer = () => Navigate("/customer-start");

  return (
    <div className="container">
      <div className="header-component border-bottom">
        <WithoutSearchBar title="Face Verification" />
      </div>
      <div className="p-3 d-flex flex-column align-items-center">
        <div className="row mt-3 text-center" style={{ maxWidth: "300px" }}>
          <div className="col-md-12">
            <img
              src={success}
              alt="Success Icon"
              className="img-fluid mb-3"
              style={{ maxWidth: "80px", maxHeight: "80px" }}
            />
          </div>
          <div className="col-md-12">
            <p
              className="form-check-label"
              style={{ fontSize: "14px", fontWeight: "600" }}
            >
              {loadingStep === 0
                ? "Your application has been submitted successfully"
                : loadingStep === 1
                ? "Your submission is under review"
                : "KYC submitted successfully"}
            </p>
          </div>
          <div className="col-md-12">
            <p className="form-check-label" style={{ fontSize: "14px" }}>
              {submitted
                ? "Congratulations! Your application for KYC with application no. 1234567890 has been submitted successfully. The KYC process might take 2-3 days to complete. We will notify you soon."
                : loadingStep === 1
                ? "Your application is being reviewed and will be done within 2 days."
                : "Please click 'Submit' to proceed with the review or click 'Retake' to retake the photo."}
            </p>
          </div>
          <div className="col-md-12 mt-3">
            <div className="row">
              {loadingStep > 0 && !submitted ? (
                <div className="row align-items-center">
                  <div
                    className="col-12"
                    style={{
                      textAlign: "left",
                      fontWeight: "600",
                      color: "#0B671A",
                    }}
                  >
                    Progress
                  </div>
                  <div className="col-3">
                    <img
                      src={loadingStep === 1 ? GreenRadio : GreenTick}
                      alt="Loading Icon"
                      className="img-fluid mb-3"
                      style={{
                        maxWidth: "30px",
                        maxHeight: "30px",
                        marginTop: "10px",
                      }}
                    />
                  </div>
                  <div
                    className="col-9"
                    style={{
                      fontSize: "1rem",
                      textAlign: "left",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {loadingStep === 1
                      ? "Submitting KYC Application"
                      : "KYC submitted successfully"}
                  </div>
                </div>
              ) : submitted ? (
                <>
                  <button
                    className="col-12 btn btn-dark me-2"
                    onClick={handleEKYCProcess}
                  >
                    Start New eKYC Process
                  </button>
                  <button
                    className="col-12 btn btn-light mt-3"
                    onClick={handleStartWithCustomer}
                    style={{ boxShadow: "1px 3px 4px rgba(0, 0, 0, 0.25)",fontWeight:"600",opacity:"0.7" }}
                  >
                    Continue to customer form
                  </button>
                </>
              ) : (
                <button
                  className="col-12 btn btn-dark me-2"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              )}
            </div>
          </div>
          {!submitted && loadingStep === 0 && (
            <div className="col-md-12 mt-3">
              <button
                className="col-12 btn btn-light me-2"
                onClick={handleRetake}
                style={{ boxShadow: "1px 3px 4px rgba(0, 0, 0, 0.25)" }}
              >
                Retake
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FaceSubmit;
