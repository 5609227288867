import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import Dashboard from "./components/dashboard/Dashboard";


function App() {
  // const token = localStorage.getItem('token');
  const token = "token";

  return (
    <div className="App">
      <Router>
       <Dashboard/>
      </Router>
    </div>
  );
}

export default App;
