import React from 'react';
import { Routes, Route } from 'react-router-dom';
import "./Dashboard.css";
import MainDashboard from './pages/MainDashbaord/MainDashboard';
import Kycs from './pages/Kycs/Kycs';
import Farmers from './pages/Farmers/Farmers';
import LandDetails from './pages/LandDetails/LandDetails';
import MenuSettings from './pages/MenuSettings/MenuSettings';
import FiledAnalysis from './pages/Farmers/FiledAnalysis/FiledAnalysis';
import FarmDetails from './pages/Farmers/FarmDetails';
import LoanDetailsAndPurpose from './pages/LoanApplicationForm/LoanDetailsAndPurpose';
import CheckListItems from './pages/LoanApplicationForm/CheckListItems';
import Guarantor from './pages/LoanApplicationForm/Guarantor';
import BussinessType from './pages/LoanApplicationForm/BussinessType';
import LivingExpense from './pages/LoanApplicationForm/LivingExpense';
import KYC_Process from './pages/LoanApplicationForm/KYC_Process';
import Sidebar from './Sidebar/Sidebar';
import Id_Verification from './pages/LoanApplicationForm/Id_Verification';
import FaceVerification from './pages/LoanApplicationForm/FaceVerification';
import FaceSubmit from './pages/LoanApplicationForm/FaceSubmit';

const Dashboard = () => {
  return (
    <>
      <div className='container-fluid bg-dark'>
     
        <div className='row'>
            <div className='col-2 sidebar_container'>
                <div className='row sidebar_row'>
                  <Sidebar/>
                </div>
            </div>
            <div className='col-9 main_container flex-grow-1'>
                <div className='row pages_row'>
                    <Routes>
                        <Route path="/" element={<MainDashboard />} />  
                        <Route path="/kycs" element={<Kycs />} />
                        <Route path="/farmers" element={<Farmers />} />
                        <Route path="/FarmDetails" element={<FarmDetails />} />
                        <Route path="/landDetails" element={<LandDetails />} />
                        <Route path="/Start_KYC_Process" element={<KYC_Process />} />
                        <Route path='/Id_Verification' element={<Id_Verification />} />
                        <Route path='/Face_Verification' element={<FaceVerification />} />
                        <Route path='/Face_Submit' element={<FaceSubmit />} />
                        <Route path="/loanApplicationForm/loanDetailsAndPurpose" element={<LoanDetailsAndPurpose />} />
                        <Route path="/loanApplicationForm/loanDetailsAndPurpose/checkListItems" element={<CheckListItems />} />
                        <Route path="/loanApplicationForm/loanDetailsAndPurpose/checkListItems/guarantor" element={<Guarantor/>}/>
                        <Route path="/loanApplicationForm/loanDetailsAndPurpose/checkListItems/guarantor/bussinessType" element={<BussinessType/>}/>
                        <Route path="/loanApplicationForm/loanDetailsAndPurpose/checkListItems/guarantor/bussinessType/livingExpense" element={<LivingExpense/>}/>
                        <Route path="/menuSettings" element={<MenuSettings />} />
                        <Route path='/FiledAnalysis' element={<FiledAnalysis />} />
                    </Routes>
                </div>
            </div>
        </div>
      </div>
    </>
  )
}

export default Dashboard
