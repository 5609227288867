import React, { useState } from "react";
import { Link } from "react-router-dom";
import WithoutSearchBar from "../../../header/WithoutSearchBar";
import { useNavigate } from "react-router-dom";

const KYC_Process = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("new");

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    console.log(e.target.value);
  };

  const handleProceed = () => {
    navigate("/Id_Verification");
  };

  return (
    <div className="container">
      <div className="header-comonent border-bottom">
        <WithoutSearchBar title="Start KYC Process" />
      </div>

      <div className="p-3 my-4">
        <div className="row mb-2 mt-3">
          <div className="col-2 form-check">
            <input
              className="form-check-input custom-radio"
              type="radio"
              id="new-application"
              name="kycOption"
              value="new"
              checked={selectedOption === "new"}
              onChange={handleOptionChange}
            />
            <label className="form-check-label" htmlFor="new-application">
              New application
            </label>
          </div>
          <div className="col-10 form-check">
            <input
              className="form-check-input custom-radio"
              type="radio"
              id="existing-application"
              name="kycOption"
              value="existing"
              checked={selectedOption === "existing"}
              onChange={handleOptionChange}
            />
            <label className="form-check-label" htmlFor="existing-application">
              Is existing application
            </label>
          </div>
        </div>
      </div>

      <div className="row ml-2">
        {selectedOption === "new" ? (
          <div>
            <button
              className="btn"
              style={{
                fontSize: "14px",
                fontWeight: "600",
                textAlign: "left",
                backgroundColor: "#0B671A",
                color: "white",
                borderRadius: "8px",
              }}
              onClick={handleProceed}
            >
              Proceed for KYC
            </button>
          </div>
        ) : (
          <div class="row">
            <div class="col-3">
              <input
                type="text"
                class="form-control"
                placeholder="Customer ID/ Application No."
                style={{ opacity: "0.7" }}
              />
            </div>
            <div class="col-2">
              <button type="button" class="btn btn-success">
                Search
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default KYC_Process;
