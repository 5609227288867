import React, { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { FaSearch, FaArrowRight } from "react-icons/fa";
import WithoutSearchBar from "../../../header/WithoutSearchBar";
import api from "../../../../Services/api";

const Kycs = () => {
  const [AllKYCs, setAllKYCs] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); // State for search input
  const [filteredKYCs, setFilteredKYCs] = useState([]); // Filtered KYCs

  const GetAllKYCs = async () => {
    try {
      const response = await api.get("/kyc/getall");
      setAllKYCs(response.data.kycsWithFarmers);
      setFilteredKYCs(response.data.kycsWithFarmers); // Initialize filtered data
    } catch (error) {
      console.error("Error fetching KYC data:", error);
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    // Filter KYCs based on the query
    const filtered = AllKYCs.filter(
      (kyc) =>
        kyc.farmername?.toLowerCase().includes(query) ||
        kyc.applicationno?.toLowerCase().includes(query) ||
        kyc.farmermobileno?.includes(query)
    );

    setFilteredKYCs(filtered);
  };

  useEffect(() => {
    GetAllKYCs();
  }, []);

  return (
    <div className="container">
      <div className="header-comonent">
        <WithoutSearchBar title="KYCs" />
      </div>
      <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center mb-4">
        <InputGroup className="mb-2 mb-md-0" style={{ maxWidth: "300px" }}>
          <InputGroup.Text
            style={{
              borderRadius: "25px 0px 0px 25px",
              backgroundColor: "white",
              border: "none",
            }}
          >
            <FaSearch />
          </InputGroup.Text>
          <Form.Control
            placeholder="Search Farmers"
            value={searchQuery}
            onChange={handleSearch}
            style={{ borderRadius: "0px 25px 25px 0px", border: "none" }}
          />
        </InputGroup>
      </div>

      {/* Table to display the data */}
      <div style={{ overflowX: "auto", marginBottom: "20px" }}>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th className="text-center">Application No.</th>
              <th className="text-center">Farmer Name</th>
              <th className="text-center">Mobile No.</th>
              <th className="text-center">Note</th>
              <th className="text-center">KYC Status</th>
              <th className="text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredKYCs.length > 0 ? (
              filteredKYCs.map((row, index) => (
                <tr key={index}>
                  <td className="text-center">
                    <div className="p-2">{row.applicationno || "N/A"}</div>
                  </td>
                  <td>
                    <div className="p-2">{row.farmername || "N/A"}</div>
                  </td>
                  <td className="text-center">
                    <div className="p-2">{row.farmermobileno || "N/A"}</div>
                  </td>
                  <td className="text-center">
                    <div className="p-2">{row.note || "No Note"}</div>
                  </td>
                  <td className="text-center">
                    <div className="p-2">
                      <span
                        style={{
                          color:
                            row.kycstatus === "Inprogress"
                              ? "#264FAB"
                              : row.kycstatus === "Pending"
                              ? "#DFA510"
                              : row.kycstatus === "Rejected"
                              ? "#EE201C"
                              : row.kycstatus === "Completed"
                              ? "#1A932E"
                              : "",
                          backgroundColor:
                            row.kycstatus === "Inprogress"
                              ? "#2B5CE61A"
                              : row.kycstatus === "Pending"
                              ? "#E2B1332E"
                              : row.kycstatus === "Rejected"
                              ? "#EE201C2E"
                              : row.kycstatus === "Completed"
                              ? "#1A932E2E"
                              : "#FFCCCC",
                          padding: "7px",
                          borderRadius: "25px",
                          fontSize: "0.8rem",
                          fontWeight: "600",
                        }}
                      >
                        {row.kycstatus || "Pending"}
                      </span>
                    </div>
                  </td>
                  <td className="text-center">
                    <FaArrowRight
                      className="action-icon cursor-pointer"
                      style={{ cursor: "pointer", color: "#0B671A" }}
                      onClick={() =>
                        console.log(`Clicked on ${row.applicationno}`)
                      }
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  No KYC data found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Kycs;
